//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '~/const';
import Document from './Document.vue';
import CheckDocumentsModal from './CheckDocumentsModal.vue';

export default {
  components: { Document, CheckDocumentsModal },

  data() {
    return {
      loader: false,
      requestLoader: false,
      fileLoader: false,
      requestIndex: 0,
      fileIndex: 0,
      documentIndex: 0,
      checkingDocuments: {
        modal: false,
        isNewDocument: false,
        data: {
          fullNameList: [],
          documentNumberList: [],
        },
        payload: {},
      },
      isUnsavedChanges: false,
      hidingModal: {
        modal: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('common', ['status']),
    ...mapState('addDocuments', [
      'modal',
      'userDocuments',
      'userVerificationRequests',
      'userFiles',
    ]),
    ...mapState('user', ['user']),
    ...mapState('settingsVerification', {
      verificationOptions: 'data',
    }),
    ...mapGetters('login', ['getAdminById']),

    show() {
      const { show } = this.modal;
      return show;
    },

    currentRequestDate() {
      const { requestIndex, userVerificationRequests } = this;

      const userVerificationRequest = userVerificationRequests[requestIndex];
      if (!userVerificationRequest) return '';

      const { dateCreated } = userVerificationRequest;
      if (!dateCreated) return '';

      return this.$utils.getDate(dateCreated);
    },

    currentFileList() {
      const { userVerificationRequests } = this;
      const currentVerificationRequest = userVerificationRequests[this.requestIndex];
      if (!currentVerificationRequest) return [];

      const { userDocumentList } = currentVerificationRequest;
      if (!userDocumentList) return [];

      return userDocumentList.map((e) => e.userFile);
    },

    currentFileId() {
      const { currentFileList } = this;

      const currentFile = currentFileList[this.fileIndex];
      if (!currentFile) return 0;

      return currentFile.id;
    },

    currentFile() {
      const { currentFileId, userFiles } = this;

      const currentFile = userFiles.find((e) => e.id === currentFileId);
      if (!currentFile) return '';

      return currentFile;
    },

    currentDocumentDate() {
      const { documentIndex, userDocuments } = this;

      const userDocument = userDocuments[documentIndex];
      if (!userDocument) return '';

      const { dateCreated } = userDocument;
      if (!dateCreated) return '';

      return this.$utils.getDate(dateCreated);
    },

    lastRequestId() {
      const lastRequest = this.userVerificationRequests[0];

      if (!lastRequest) return 0;
      return lastRequest.id;
    },

    isSetStatusDisabled() {
      const lastRequest = this.userVerificationRequests[0];
      const {
        VERIFICATION_REQUEST,
        VERIFIED_VERIFICATION_REQUEST,
      } = constant.user.VERIFICATION_STATUS;

      if (!lastRequest) return true;
      if (
        ![
          VERIFICATION_REQUEST,
          VERIFIED_VERIFICATION_REQUEST,
        ].includes(lastRequest.verificationStatus)
      ) return true;
      return false;
    },

    isSendToExternalServiceDisabled() {
      const { isSetStatusDisabled } = this;
      const { verificationType } = this.verificationOptions;
      return (
        (verificationType !== constant.settings.VERIFICATION_TYPE.MANUAL_AUTO)
        || isSetStatusDisabled
      );
    },

    // isVerifyDisabled() {
    //   const { VERIFICATED } = constant.users.USER_STATUS;
    //   const { userStatus } = this.user;
    //   return [VERIFICATED].includes(userStatus);
    // },

    // isRejectDisabled() {
    //   const { NEW, CONFIRMED, REJECTED } = constant.users.USER_STATUS;
    //   const { userStatus } = this.user;
    //   return [NEW, CONFIRMED, REJECTED].includes(userStatus);
    // },
  },

  watch: {
    async show(value) {
      if (value) {
        this.loader = true;
        this.requestLoader = true;
        await Promise.allSettled([
          this.loadUserDocuments(),
          this.loadUserVerificationRequests(),
          this.loadVerificationOptions(),
        ]);

        this.fileIndex = 0;

        const { userVerificationRequests } = this;
        const { requestId } = this.modal;
        const requestIndex = userVerificationRequests.findIndex((e) => e.id === requestId);
        if (requestIndex === -1) {
          this.requestIndex = 0;
        } else {
          this.requestIndex = requestIndex;
        }

        this.loader = false;
        this.requestLoader = false;
      }
    },

    async currentFileId(value) {
      if (!value) return;

      this.loader = true;
      this.fileLoader = true;
      await this.loadUserFile(value);
      this.loader = false;
      this.fileLoader = false;
    },
  },

  beforeDestroy() {
    // clean the fat store :)
    this.cleanUserFiles();
  },

  methods: {
    ...mapActions('common', [
      'setErrorNotification',
      'setSuccessNotification',
      'setGeneralProgress',
      'confirmAction',
    ]),
    ...mapActions('addDocuments', [
      'loadUserDocuments',
      'loadUserVerificationRequests',
      'loadUserFile',
      'cleanUserFiles',
      'setModal',
      'addUserDocument',
      'updateUserDocument',
      'setUserVerification',
      'adminVerificationResponse',
      'setVerificationStatus',
      'manualVerificationResponse',
    ]),
    ...mapActions('userId', {
      loadUserDocumentsToUserId: 'loadUserDocuments',
      checkUserDocumentAction: 'checkUserDocument',
    }),
    ...mapActions('user', ['loadUser', 'silentLoadUser']),
    ...mapActions('users', ['getUsersByParams']),
    ...mapActions('userVerificationRequests', {
      loadUserVerificationTable: 'loadData',
    }),
    ...mapActions('settingsVerification', {
      loadVerificationOptions: 'loadData',
    }),

    hide() {
      const { isUnsavedChanges } = this;
      const { modal } = this.hidingModal;
      if (isUnsavedChanges) {
        if (!modal) this.hidingModal.modal = true;
      } else {
        this.applyHide();
      }
    },

    applyHide() {
      this.setModal({
        show: false,
        requestId: 0,
      });
      setTimeout(() => {
        this.hidingModal.modal = false;
        this.isUnsavedChanges = false;
      }, 50);
    },

    setRequest(i) {
      this.fileIndex = 0;
      this.requestIndex += i;
    },

    setDocument(i) {
      this.documentIndex += i;
    },

    openInTab(src) {
      const tab = window.open();
      tab.document.body.innerHTML = `<img src="${src}"/>`;
    },

    getCreatedBy(adminId) {
      const admin = this.getAdminById(adminId);
      if (!admin) return this.$t('unknown admin');

      return admin.email;
    },

    async checkDocuments({
      documentNumber,
      documentType,
      firstName,
      middleName,
      lastName,
    }) {
      const payload = {
        documentNumber,
        documentType,
        firstName,
        middleName,
        lastName,
      };
      if (!documentNumber && !firstName && !middleName && !lastName) return null;
      try {
        const data = await this.checkUserDocumentAction(payload);
        const userId = this.user.id;
        const noMatches = (
          (
            !data.fullNameList.length
            || (data.fullNameList.length === 1 && data.fullNameList[0] === userId)
          ) && (
            !data.documentNumberList.length
            || (data.documentNumberList.length === 1 && data.documentNumberList[0] === userId)
          )
        );

        if (noMatches) return null;
        return data;
      } catch (error) {
        this.setErrorNotification(error.message);
        return null;
      }
    },

    async checkBeforeAdd(data) {
      this.checkingDocuments.payload = data;
      this.setGeneralProgress(true);
      const checkResult = await this.checkDocuments(data);
      if (checkResult) {
        if (checkResult.documentNumberList?.length) {
          const documentNumberList = await this.getUsersByParams({
            userIdList: checkResult.documentNumberList,
            limit: 1000,
          });
          this.checkingDocuments.data.documentNumberList = documentNumberList.items;
        } else {
          this.checkingDocuments.data.documentNumberList = [];
        }
        if (checkResult.fullNameList?.length) {
          const fullNameList = await this.getUsersByParams({
            userIdList: checkResult.fullNameList,
            limit: 1000,
          });
          this.checkingDocuments.data.fullNameList = fullNameList.items;
        } else {
          this.checkingDocuments.data.fullNameList = [];
        }
        this.checkingDocuments.isNewDocument = true;
        this.checkingDocuments.modal = true;
      } else {
        this.add();
      }
      this.setGeneralProgress(false);
    },

    async checkBeforeUpdate(data) {
      this.checkingDocuments.payload = data;
      this.setGeneralProgress(true);
      const checkResult = await this.checkDocuments(data);
      if (checkResult) {
        if (checkResult.documentNumberList?.length) {
          const documentNumberList = await this.getUsersByParams({
            userIdList: checkResult.documentNumberList,
            limit: 1000,
          });
          this.checkingDocuments.data.documentNumberList = documentNumberList.items;
        } else {
          this.checkingDocuments.data.documentNumberList = [];
        }
        if (checkResult.fullNameList?.length) {
          const fullNameList = await this.getUsersByParams({
            userIdList: checkResult.fullNameList,
            limit: 1000,
          });
          this.checkingDocuments.data.fullNameList = fullNameList.items;
        } else {
          this.checkingDocuments.data.fullNameList = [];
        }
        this.checkingDocuments.isNewDocument = false;
        this.checkingDocuments.modal = true;
      } else {
        this.update();
      }
      this.setGeneralProgress(false);
    },

    async add() {
      const { payload } = this.checkingDocuments;
      this.setGeneralProgress(true);
      try {
        const userId = this.user.id;
        await this.addUserDocument({
          userId,
          ...payload,
        });
        await this.loadUserDocuments();
        await this.loadUserDocumentsToUserId();
        this.setSuccessNotification('Document added');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.isUnsavedChanges = false;
    },

    async update() {
      const { payload } = this.checkingDocuments;
      this.setGeneralProgress(true);
      try {
        await this.updateUserDocument(payload);
        await this.loadUserDocuments();
        await this.loadUserDocumentsToUserId();
        this.setSuccessNotification('Document updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
      this.isUnsavedChanges = false;
    },

    async setVerification(verificationResponseType, force = false) {
      const { lastRequestId } = this;
      const { id, language } = this.user;

      this.confirmAction({
        title: 'Are you sure?',
        hasPrompt: true,
        promptRequired: true,
        promptLabel: this.$t('confirmAction.Comment for user $language', { language: this.status.languageList[language].toUpperCase() }),
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.manualVerificationResponse({
            verificationRequestId: lastRequestId,
            verificationResponseType,
            comment: prompt,
            force,
          });
          await this.silentLoadUser(id);
          await this.loadUserVerificationRequests({});
          await this.loadUserVerificationTable({});
          this.setGeneralProgress(false);
          this.setSuccessNotification('Verification status updated');
        },
      });
    },

    showOnError(message) {
      this.setErrorNotification(message);
    },
  },
};
