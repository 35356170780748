//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import IdentificationTable from '~/views/UserId/IdentificationTable.vue';
import WarningTable from '~/views/UserId/WarningTable.vue';
import BlockTable from '~/views/UserId/BlockTable.vue';
import BasicInfo from '~/views/UserId/BasicInfo.vue';
import UserVerificationRequests from '~/views/UserId/UserVerificationRequests/UserVerificationRequests.vue';
import AddDocuments from '~/views/UserId/UserVerificationRequests/modals/AddDocuments/AddDocuments.vue';
import DeleteUser from '~/views/UserId/DeleteUser.vue';
import UserRequisites from '~/views/UserId/UserRequisites/UserRequisites.vue';

export default {
  components: {
    IdentificationTable,
    BasicInfo,
    UserRequisites,
    WarningTable,
    BlockTable,
    UserVerificationRequests,
    AddDocuments,
    DeleteUser,
  },

  computed: {
    ...mapState('user', ['user']),
  },

  async mounted() {
    this.setGeneralLoader(true);

    if (!process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old')) {
      await this.loadUserDocuments();
      await this.loadUserVerificationRequests();
      await this.loadP2pArbitratorsList();
    } else {
      await this.loadFees();
      await this.loadPersonalFees({ userId: this.user?.id });
      await this.loadDeals({ userId: this.user?.id });
      await this.loadUserDevices({ userId: this.user?.id });
      await this.loadTradersCurrencies({ limit: 1000 });
      await this.loadUserRequisites({ userId: this.user?.id });
      await this.loadTradersOffers({ userId: this.user?.id });
      await this.loadUserGoups();
      await this.loadPaymentMethods();
    }
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification']),
    ...mapActions('userId', ['loadUserDocuments']),
    ...mapActions('userRequisites', {
      loadUserRequisites: 'loadData',
    }),
    ...mapActions('userVerificationRequests', {
      loadUserVerificationRequests: 'loadData',
    }),
    ...mapActions('tradersDeals', {
      loadDeals: 'loadData',
    }),
    ...mapActions('p2pArbitratorsList', {
      loadP2pArbitratorsList: 'loadData',
    }),
    ...mapActions('tradersFees', {
      loadPersonalFees: 'loadPersonalFeeDataById',
    }),
    ...mapActions('tradersFees', {
      loadFees: 'loadData',
    }),
    ...mapActions('tradersCurrencies', {
      loadTradersCurrencies: 'loadData',
    }),
    ...mapActions('tradersOffers', {
      loadTradersOffers: 'loadData',
    }),
    ...mapActions('tradersPaymentMethods', {
      loadPaymentMethods: 'loadData',
    }),
    ...mapActions('userDevices', {
      loadUserDevices: 'loadData',
    }),
    ...mapActions('usersGroups', {
      loadUserGoups: 'getGroups',
    }),
  },
};
