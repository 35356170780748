/* eslint-disable import/prefer-default-export */

export const basicInfoInfoTable = [
  'Username:',
  'Id:',
  'Reg date:',
  'Identification date:',
  'Identification:',
  'Country:',
  'Email:',
  'Roles:',
  'Language:',
  'Groups',
  'Fees:',
  'Previous owners:',
  'Tags:',
  'Contacts:',
  'MFA:',
  'UTM List:',
  'Last login:',
  'New identificator:',
];
