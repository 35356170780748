import utils from '~/utils';

const { getFullDate } = utils;

export default [
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Requests id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'text',
      value: (e) => getFullDate(e.dateCreated),
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'IP Address',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 28,
    header: {
      type: 'text',
      caption: 'Comment',
    },
    cell: {
      type: 'slot',
      name: 'comment',
    },
  },
  {
    width: 40,
    header: {
      type: 'text',
      caption: 'Documents',
    },
    cell: {
      type: 'slot',
      name: 'documents',
    },
  },
];
