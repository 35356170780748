//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      state: {
        address: '',
        birthday: '',
        city: '',
        country: '',
        documentAddress: '',
        documentExpireDate: '',
        documentIssuedBy: '',
        documentIssuedDate: '',
        documentNumber: '',
        documentType: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        postalCode: '',
      },
    };
  },

  computed: {
    fields() {
      return {
        firstName: { type: 'text', caption: this.$t('First name') },
        lastName: { type: 'text', caption: this.$t('Last name') },
        middleName: { type: 'text', caption: this.$t('Middle name') },
        birthday: { type: 'date', caption: this.$t('Birthday') },
        country: { type: 'text', caption: this.$t('Country') },
        city: { type: 'text', caption: this.$t('City') },
        address: { type: 'text', caption: this.$t('Address') },
        postalCode: { type: 'number', caption: this.$t('Postal code') },
        contacts: { type: 'text', caption: this.$t('Contacts') },
        adminComment: { type: 'text', caption: this.$t('Admin comments') },
        documentType: {
          type: 'select',
          caption: this.$t('Document type'),
          list: constant.user.DOCUMENT_TYPE_LIST,
        },
        documentNumber: { type: 'text', caption: this.$t('Document number') },
        documentExpireDate: { type: 'date', caption: this.$t('Document expire date') },
        documentIssuedDate: { type: 'date', caption: this.$t('Document issued date') },
        documentIssuedBy: { type: 'text', caption: this.$t('Document issued by') },
        documentAddress: { type: 'text', caption: this.$t('Document address') },
      };
    },
  },

  created() {
    const { fields, data } = this;
    // eslint-disable-next-line
    for (const key in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        this.state[key] = data[key];
      }
    }
    this.$watch(
      'state',
      () => {
        this.$emit('change');
      },
      {
        deep: true,
      },
    );
  },

  methods: {
    edit() {
      const { state } = this;
      const { id } = this.data;
      this.$emit('update', {
        id,
        ...state,
      });
    },

    setOutdated() {
      const { id } = this.data;
      this.$emit('update', {
        id,
        outdated: false,
      });
    },

    add() {
      const { state } = this;
      this.$emit('add', state);
    },

    onErrorDate(message) {
      this.$emit('error', message);
    },
  },
};
