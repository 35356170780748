//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import WarningAlert from '~/components/users/WarningAlert.vue';

export default {
  components: {
    WarningAlert,
  },

  computed: {
    ...mapState('user', ['user']),

    userWarningList() {
      const { userWarningList } = this.user;
      if (userWarningList && userWarningList.length) {
        return userWarningList;
      }
      return [];
    },
  },
};
