//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import BlockAlert from '~/components/users/BlockAlert.vue';
import constant from '~/const';

export default {
  components: {
    BlockAlert,
  },

  data() {
    return {
      constant,
    };
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setSuccessNotification',
      'setErrorNotification',
      'setGeneralProgress',
    ]),
    ...mapActions('users', {
      usersUnlockAction: 'usersUnlock',
      usersUnblockAction: 'usersUnblock',
    }),
    ...mapActions('user', ['silentLoadUser']),
    unlockUser(userId, type) {
      this.confirmAction({
        text: 'Are you sure you want to unlock user?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.blocksLoader = true;
          this.setGeneralProgress(true);
          try {
            if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
              await this.usersUnblockAction({
                userIds: [userId],
                comment: prompt,
              });
            } else {
              await this.usersUnlockAction({
                userIds: [userId],
                comment: prompt,
                type,
              });
            }
            await this.silentLoadUser(userId);
            this.setSuccessNotification('User unlocked');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.blocksLoader = false;
          this.setGeneralProgress(false);
        },
      });
    },
  },

  computed: {
    ...mapState('user', ['user']),

    userLockList() {
      const { userLockList } = this.user;
      if (userLockList && userLockList.length) {
        return userLockList;
      }
      return [];
    },
  },
};
