//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UserPersonalFeesModal from '~/views/UserPersonalFees/Modal.vue';
import constant from '~/const';
import openModalMixin from '~/views/UserPersonalFees/openModalMixin';
import TableKeyValue from '~/components/ui/TableKeyValue.vue';
import Tag from '~/components/tags/Tag.vue';
import AddTagToUser from '~/components/tags/AddTagToUser.vue';
import NfaList from '~/components/users/NfaList.vue';
import UTMList from '~/components/users/UTMList.vue';
import Activity from '~/components/users/Activity.vue';
import User from '../../components/User.vue';
import { basicInfoInfoTable } from './exclude';

export default {
  components: {
    TableKeyValue,
    Tag,
    AddTagToUser,
    NfaList,
    UTMList,
    Activity,
    User,
    UserPersonalFeesModal,
  },

  mixins: [openModalMixin],

  data() {
    return {
      infoLoader: false,
      blocksLoader: false,
      constant,
      newIdentificator: '',
    };
  },

  created() {
    if (this.$route.query.edit_fees) {
      this.openEditPersonalFeesModal(this.currentUserPersonalFees, this.user.id);
    }
  },

  computed: {
    ...mapState('common', ['generalLoader', 'generalProgress', 'languages']),
    ...mapState('users', ['userTagsList']),
    ...mapState('user', ['user']),
    ...mapState('tradersFees', ['personalDataRecord']),
    ...mapState('usersGroups', ['groups']),
    ...mapGetters('users', ['getUserTagsByIds']),
    ...mapGetters('usersGroups', ['usersGroups']),

    getLanguages() {
      return this.constant.languages.LANGUAGES_LIST.filter((lang) => this.languages.includes(lang.code.toLowerCase()));
    },

    infoTable() {
      const {
        userName,
        id,
        dateCreated,
        verificationDate,
        identificatorList,
        userStatus,
        phone,
      } = this.user;
      return [
        { key: 'Username:', value: userName },
        { key: 'Id:', value: id },
        { key: 'Reg date:', value: this.$utils.getDate(dateCreated) },
        {
          key: 'Identification date:',
          value: verificationDate
            ? this.$utils.getFullDate(verificationDate)
            : '–',
        },
        { key: 'Identification:', value: this.getIdentificationStatus(userStatus) },
        { key: 'Country:', slotName: 'country' },
        { key: 'Email:', slotName: 'email' },
        { key: 'Roles:', slotName: 'roles' },
        { key: 'Language:', slotName: 'language' },
        this.userIsMerchantOrTrader && { key: 'Groups', slotName: 'groups' },
        // @if process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old')
        this.showPersonalFees && { key: 'Fees:', slotName: 'fees' },
        // @endif
        { key: 'Previous owners:', slotName: 'previous' },
        { key: 'Mobile Number:', value: this.$utils.isIdentificatorType(identificatorList, 3) },
        { key: 'Telegram Id:', value: this.$utils.isIdentificatorType(identificatorList, 1) },
        { key: 'Tags:', slotName: 'tags' },
        { key: 'Contacts:', value: phone },
        { key: 'MFA:', slotName: 'mfa' },
        { key: 'UTM List:', slotName: 'utm' },
        { key: 'Telegram Bot:', slotName: 'telegramBot' },
        { key: 'Last login:', slotName: 'lastLogin' },
        { key: 'New identificator:', slotName: 'setNewIdentificator' },
        // { key: 'Activity:', slotName: 'activity' },
      ].filter((e) => basicInfoInfoTable.includes(e?.key));
    },

    userIsMerchantOrTrader() {
      const rolesForGroup = [constant.users.USER_ROLE.MERCHANT, constant.users.USER_ROLE.TRADER];
      return this.user.roles && this.user.roles.some((role) => rolesForGroup.includes(role));
    },
    userGroups() {
      return this.usersGroups ? this.usersGroups[this.user.id] : [];
    },

    rolesValue() {
      return this.user.roles;
    },

    languageValue() {
      if (Object.keys(this.user).length > 0) {
        return { id: this.user?.language, caption: this.$t(`const.${constant.languages.LANGUAGES_LIST[this.user?.language].caption}`) };
      }
      return '';
    },

    userWarningList() {
      const { userWarningList } = this.user;
      if (userWarningList && userWarningList.length) {
        return userWarningList;
      }
      return [];
    },

    userLockList() {
      const { userLockList } = this.user;
      if (userLockList && userLockList.length) {
        return userLockList;
      }
      return [];
    },

    isBlocksScroll() {
      const { userLockList, userWarningList } = this.user;
      if (
        userLockList
        && userWarningList
        && typeof userLockList.length === 'number'
        && typeof userWarningList.length === 'number'
      ) {
        return (userLockList.length + userWarningList.length) > 5;
      }
      return false;
    },

    hasUTMList() {
      return Array.isArray(this.user.utmList);
    },

    userTelegramBot() {
      return this.user.nfaList?.filter(({ nfaServiceType }) => nfaServiceType === 1);
    },

    currentUserPersonalFees() {
      return this.personalDataRecord;
    },

    showPersonalFees() {
      return this.showMerchantFees || this.showTraderFees;
    },
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
      'setSuccessNotification',
      'setErrorNotification',
      'setGeneralProgress',
      'setGeneralLoader',
      'changeUserLanguage',
    ]),
    ...mapActions('users', {
      removeTagsFromUserAction: 'removeTagsFromUser',
      addTagsToUserAction: 'addTagsToUser',
      usersUnlockAction: 'usersUnlock',
      usersUnblockAction: 'usersUnblock',
      getUserTagsListAction: 'getUserTagsList',
      addUserRoleAction: 'addUserRole',
      removeUserRoleAction: 'removeUserRole',
      changeIdentificatorAction: 'changeIdentificator',
    }),
    ...mapActions('user', ['loadUser', 'silentLoadUser']),
    ...mapActions('usersGroups', {
      loadUserGoups: 'getGroups',
      addUsersToGroup: 'addUsersToGroup',
      removeUsersFromGroup: 'removeUsersFromGroup',
    }),
    ...mapActions('tradersFees', {
      editPersonalFees: 'editPersonalFees',
    }),

    async setRole(value) {
      const toSet = value.filter((e) => !this.user.roles.includes(e))[0];
      const toRemove = this.user.roles.filter((e) => !value.includes(e))[0];
      this.setGeneralProgress(true);
      const isP2Pay = this.$utils.getAppType() === 'p2pay';
      const id = { [isP2Pay ? 'id' : 'userId']: isP2Pay ? String(this.user.id) : this.user.id };
      const toSetRole = isP2Pay ? this.constant.users.USER_ROLE_LIST_P2PAY[toSet] : toSet;
      const toRemoveRole = isP2Pay ? this.constant.users.USER_ROLE_LIST_P2PAY[toRemove] : toRemove;
      if (toSet) {
        await this.addUserRoleAction({
          ...id,
          role: toSetRole,
          comment: '',
        });
      }
      if (toRemove) {
        await this.removeUserRoleAction({
          ...id,
          role: toRemoveRole,
          comment: '',
        });
      }
      await this.loadUser(this.user.id);
      this.setGeneralProgress(false);
    },

    closeFeesModal() {
      this.closeModal();
    },

    async setLanguage(language) {
      try {
        this.setGeneralLoader(true);
        if (process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old')) {
          await this.changeUserLanguage({ id: String(this.user.id), language: constant.languages.LANGUAGES_LIST[language].code });
        } else {
          await this.changeUserLanguage({ userId: String(this.user.id), Language: constant.languages.LANGUAGES_LIST[language].code });
        }
        await this.loadUser(this.user.id);
        this.setGeneralLoader(false);
        this.setSuccessNotification('Language successfully changed');
      } catch (error) {
        this.setErrorNotification(error.message);
        await this.loadUser(this.user.id);
        this.setGeneralLoader(false);
      }
    },

    async setNewIdentificator() {
      this.setGeneralProgress(true);
      if (this.newIdentificator.length > 0) {
        await this.changeIdentificatorAction({
          id: this.infoTable[1].value,
          newIdentificator: this.newIdentificator,
        });
      } else {
        this.setErrorNotification('New identificator field is empty');
      }
      this.setGeneralProgress(false);
      await this.loadUser(this.user.id);
    },

    async setGroup(groups, userId) {
      const toSet = groups.filter((e) => (!this.userGroups || !this.userGroups.includes(e)))[0];
      const toRemove = (this.userGroups || []).filter((e) => !groups.includes(e))[0];

      if (this.userGroups?.length === 1 && this.userGroups[0] === toRemove) {
        this.setErrorNotification('ProfileWithoutGroup');
      } else {
        this.setGeneralProgress(true);
        try {
          if (toSet) {
            await this.addUsersToGroup({
              userId: [userId],
              id: toSet,
            });
          }
          if (toRemove) {
            await this.removeUsersFromGroup({
              userId: [userId],
              id: toRemove,
            });
          }
          await this.loadUserGoups();
        } catch (error) {
          this.setErrorNotification(error.message);
        }
        this.setGeneralProgress(false);
      }
    },

    async removeTagFromUser(userId, tagId) {
      this.infoLoader = true;
      this.setGeneralProgress(true);
      await this.removeTagsFromUserAction({
        userId,
        userTagList: [tagId],
      });
      const { id } = this.$route.params;
      await this.loadUser(id);
      await this.getUserTagsListAction();
      this.infoLoader = false;
      this.setGeneralProgress(false);
      this.setSuccessNotification('Tag successfully removed');
    },

    async addTagToUser(tagId, userId) {
      this.infoLoader = true;
      this.setGeneralProgress(true);
      await this.addTagsToUserAction({
        userIdList: [userId],
        userTagList: [tagId],
      });
      const { id } = this.$route.params;
      await this.loadUser(id);
      await this.getUserTagsListAction();
      this.infoLoader = false;
      this.setGeneralProgress(false);
      this.setSuccessNotification('Tag successfully added');
    },

    getIdentificationStatus(id) {
      const { USER_STATUS_LIST } = constant.users;
      const userStatus = USER_STATUS_LIST.find((e) => e.id === id);
      if (userStatus) {
        return userStatus.caption;
      }
      return '';
    },

    unlockUser(userId, type) {
      this.confirmAction({
        text: 'Are you sure you want to unlock user?',
        hasPrompt: true,
        promptRequired: true,
        callback: async ({ prompt }) => {
          this.blocksLoader = true;
          this.setGeneralProgress(true);
          try {
            if (type === constant.users.USER_LOCK_TYPE.GENERAL) {
              await this.usersUnblockAction({
                userIds: [userId],
                comment: prompt,
              });
            } else {
              await this.usersUnlockAction({
                userIds: [userId],
                comment: prompt,
                type,
              });
            }
            await this.silentLoadUser(userId);
            this.setSuccessNotification('User unlocked');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.blocksLoader = false;
          this.setGeneralProgress(false);
        },
      });
    },

    applyEditPersonalFees(data) {
      const userId = this.$route.params.id;
      this.confirmAction({
        title: this.$t('confirmAction.Are you sure you want to update fees of user with id', { id: userId }),
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.editPersonalFees({ ...data });
            this.setSuccessNotification('Personal fees has been edited');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    itemDisabledList(role) {
      if (
        (this.rolesValue?.includes?.(constant.users.USER_ROLE.TRADER) && role.id === constant.users.USER_ROLE.MERCHANT)
        || (this.rolesValue?.includes?.(constant.users.USER_ROLE.MERCHANT) && role.id === constant.users.USER_ROLE.TRADER)) {
        return 'disabled';
      }

      return null;
    },
  },
};
